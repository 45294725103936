import { createEffect } from "solid-js";
import { RouteSectionProps } from "@solidjs/router";

import AppRouter from "@repo/utils-solid/AppRouter";

import { useAdmin } from "~/state/features/admin";

export default function AuthLayout({ children }: D<RouteSectionProps>) {
  const [admin] = useAdmin();

  createEffect(() => {
    if (admin.authenticated())
      AppRouter.replace(
        new URLSearchParams(AppRouter.search).get("redirect") || "/"
      );
  });

  return <>{children}</>;
}
